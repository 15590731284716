<template>
  <div>
    <cabecalho-pagina
      :titulo="$t('modulos.participantes.titulos.listagem')"
      :total-registros="tabela.quantidadeItens"
      :titulo-botao-novo="$t('modulos.participantes.titulos.novo')"
      :sem-botao-ok="!buscarPermissao('Participante', 'Inserir')"
      :sem-filtro="!buscarPermissao('Participante', 'Listar')"
      @abrirNovo="abrirNovo"
      @filtrar="filtrar"
    >
      <template v-slot:filtro="{ filtro }">
        <filtro :filtro="filtro" />
      </template>
    </cabecalho-pagina>
    <botao-padrao
      v-if="podeExcluir && buscarPermissao('Participante', 'Deletar')"
      class="my-2"
      outlined
      color="danger"
      @click="excluirRegistros"
    >
      <v-icon>$mdiTrashCanOutline</v-icon>
      {{ $t('geral.botoes.excluir') }}
    </botao-padrao>
    <tabela-padrao-prime-vue
      v-model="tabela.selecionados"
      class="mt-2"
      :colunas="tabela.colunas"
      mostra-header
      :dados="tabela.dados"
      :mostrar-acoes="true"
      selecionar-colunas
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      filtros-com-colunas
      @paginar="listarRegistros"
      @filtrarGlobal="filtrarGlobal"
      @filtroGlobal="(v) => (filtroAplicado.filter = v)"
      @nova-ordenacao="ordenacaoColuna"
    >
      <template v-slot:acoes="{ slotProps }">
        <dropdown-padrao
          v-if="buscarPermissao('Participante', 'Editar')"
          text
          color="secondary"
        >
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <div>
            <dropdown-padrao-item
              text
              color="secondary"

              @click="abrirEditar(slotProps.data)"
            >
              {{ $t('geral.botoes.editar') }}
            </dropdown-padrao-item>
          </div>
        </dropdown-padrao>
      </template>
    </tabela-padrao-prime-vue>
  </div>
</template>
<script>
import CabecalhoPagina from '@components/layout/CabecalhoPagina.vue';
import ParticipanteService from '@common/services/cadastros/ParticipanteService';
import TabelaPadraoPrimeVue from '../../../../components/tabelas/TabelaPadraoPrimeVue';
import Filtro from './filtro';
import helpers from '@common/utils/helpers';
export default {
  components: {
    CabecalhoPagina,
    Filtro,
    TabelaPadraoPrimeVue,
  },
  data() {
    return {
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'apelido',
            text: this.$t('modulos.participantes.tabela.apelido'),
            sortable: true,
          },
          {
            value: 'nome',
            text: this.$t('modulos.participantes.tabela.nome'),
            sortable: true,
          },
          {
            value: 'tipoParticipante',
            text: this.$t('modulos.participantes.tabela.tipo_participante'),
            sortable: true,
            formatter: (v) => {
              return helpers.TipoParticipanteEnum.find((el) => el.value == v)
                ?.text;
            },
          },
          {
            value: 'municipioSede.nome',
            text: this.$t('modulos.participantes.tabela.municipio'),
            sortable: false,
          },
          {
            value: 'bairroSede',
            text: this.$t('modulos.participantes.tabela.bairro'),
            sortable: true,
          },
          {
            value: 'documento',
            text: this.$t('modulos.participantes.tabela.documento'),
            sortable: true,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 0,
        porPagina: 10,
      },
      filtroAplicado: { filter: '' },
      colunaOrdenada: '',
    };
  },
  computed: {
    podeExcluir: function () {
      return this.tabela.selecionados.length > 0;
    },
  },
  created(){
    helpers.redirecionarSemPermissao(this, 'Participante', 'Listar');
    const filtros = this.buscarStateFiltros();
    this.filtroAplicado = filtros?.filtroAplicado ?? { filter: '' };
    this.tabela.paginaAtual = filtros?.paginaAtual ?? 1;
    this.tabela.porPagina = filtros?.porPagina ?? 10;
  },
  mounted() {
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.participantes.titulos.listagem')
    );
    this.colunaOrdenada = 'Nome';
    this.listarRegistros();

  },
  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(this, funcionalidade, funcionalidadeAcao);
    },
    filtrarGlobal() {
      this.listarRegistros();
    },
    filtrar: function (filtro) {
      const propertiesToReplace = ['documento', 'telefone'];

      for (const prop in filtro) {
        if (
          filtro[prop] &&
          propertiesToReplace.includes(prop) &&
          typeof filtro[prop] === 'string'
        ) {
          filtro[prop] = filtro[prop].replace(/[^a-zA-Z0-9]/g, '');
        }
      }

      this.filtroAplicado = { ...filtro, filter: this.filtroAplicado.filter };
      this.listarRegistros();
    },
    listarRegistros: function (paginaAtual = this.tabela.paginaAtual, porPagina = this.tabela.porPagina) {
      this.tabela.porPagina = porPagina;
      this.salvarStateFiltros({ filtroAplicado: this.filtroAplicado, paginaAtual, porPagina});
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        sort: this.colunaOrdenada,
        ...this.filtroAplicado,
      };

      this.$store.dispatch('Layout/iniciarCarregamento');
      this.tabela.dados = [];
      ParticipanteService.listar(parametros)
        .then((res) => {
          this.formatarDocumentoeTelefone_TipoParticipante(res.data.items);
          this.tabela.dados = res.data.items;
          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    excluirRegistros: async function () {
      this.confirmarExcluir().then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');
        let promises = [];
        this.tabela.selecionados.forEach((registro) => {
          promises.push(ParticipanteService.excluir(registro.id));
        });

        Promise.all(promises)
          .then(() => {
            this.listarRegistros(
              this.tabela.paginaAtual,
              this.tabela.porPagina
            );
            this.toastSucesso(
              this.$t(`modulos.participantes.exclusao_sucesso`)
            );
            this.tabela.selecionados = [];
          })
          .catch((err) => {
            this.toastAlerta(
              this.$t(`geral.erros.${err?.response?.data?.errors[0]}`)
            );
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },
    abrirNovo: function () {
      this.$router.push({ name: 'participantes-novo' });
    },
    abrirEditar: function (item) {
      this.$router.push({
        name: 'participantes-editar',
        params: { id: item.id },
      });
    },
    formatarDocumentoeTelefone_TipoParticipante(participantes) {
      participantes.forEach((participante) => {
        if (participante.documento.length > 11) {
          participante.documento = helpers.maskCnpj(participante.documento);
        } else {
          participante.documento = helpers.maskCpf(participante.documento);
        }
        participante.telefone = helpers.formatarTelefone(participante.telefone);

        if (participante.flagCliente & !participante.flagFornecedor) {
          participante.tipoParticipante = 1;
        } else if (!participante.flagCliente & participante.flagFornecedor) {
          participante.tipoParticipante = 2;
        } else {
          participante.tipoParticipante = 3;
        }
      });
    },
    buscarStateFiltros() {
      return this.$store.getters['Filtros/filtros'][this.$route.name]
    },
    salvarStateFiltros(dados) {
      this.$store.dispatch('Filtros/adicionarFiltro',
        {
          rota: this.$route.name, dados
        })
    },
    ordenacaoColuna(ordem) {
      this.colunaOrdenada = ordem;
      this.listarRegistros()
    },
  },
};
</script>
