<template>
  <div class="row">
    <input-text
      v-model="filtro.apelido"
      class="col-12 col-md-6"
      :label="$t('modulos.participantes.filtro.apelido')"
      :placeholder="$t('modulos.participantes.filtro.apelido')"
      em-filtro
    />
    <input-text
      v-model="filtro.nome"
      class="col-12 col-md-6"
      :label="$t('modulos.participantes.filtro.nome')"
      :placeholder="$t('modulos.participantes.filtro.nome')"
      em-filtro
    />
    <input-select
      v-model="filtro.tipoClienteFornecedor"
      class="col-12 col-md-6"
      :label="$t('modulos.participantes.filtro.tipo_participante')"
      :placeholder="$t('geral.inputs.selecione')"
      :options="opcoes.tipoClienteFornecedor"
      em-filtro
      retornar-objeto
    />
    <input-text
      v-model="filtro.nomeMunicipioSede"
      class="col-12 col-md-6"
      :label="$t('modulos.participantes.filtro.municipio')"
      :placeholder="$t('modulos.participantes.filtro.municipio')"
      :mascara="false"
      em-filtro
    />
    <input-text
      v-model="filtro.nomeBairroSede"
      class="col-12 col-md-6"
      :label="$t('modulos.participantes.filtro.bairro')"
      :placeholder="$t('modulos.participantes.filtro.bairro')"
      em-filtro
      :mascara="false"
    />
    <input-text
      v-model="filtro.documento"
      class="col-12 col-md-6"
      :label="$t('modulos.participantes.filtro.documento')"
      :placeholder="$t('modulos.participantes.filtro.documento')"
      :mascara="false"
      em-filtro
    />
  </div>
</template>
<script>
import helpers from '@common/utils/helpers';
export default {
  props: {
    filtro: {},
  },
  data() {
    return {
      opcoes: {
        tipoClienteFornecedor: helpers.TipoParticipanteEnum,
      },
    };
  },
};
</script>
